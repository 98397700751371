import { Routes, Route, Link, Navigate, useLocation,useNavigate } from "react-router-dom";
 import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import React, {useRef,useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import headerBackground from "./Images/header-bg-.jpeg";
import { makeStyles } from '@mui/styles';
import masjidImage from "./Images/majidImage.jpeg"
import Button from "@mui/material/Button";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import aicLogo from "./Images/aic-logo.png" 
import servicesDailyNamaz from "./Images/servicesDailyNamaz.jpeg"
import servicesNikah from "./Images/servicesNikah.jpeg"
import servicesCommunity from "./Images/servicesCommunity.jpeg"
import servicesFinacialaid from "./Images/servicesFinacialaid.jpeg"
import zelleImage from "./Images/zelle.jpeg"
import checkImage from "./Images/check.jpeg"
import pledgeImage from "./Images/pledge.jpeg"
import summercamp from "./Images/summercamp.jpeg"
import emailjs from '@emailjs/browser';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import Modal from '@mui/material/Modal';
import ButtonGroup from '@mui/material/ButtonGroup';

import './App.css'
import addressLogo from "./Images/addressLogo.png"
import phoneicon from "./Images/phone-icon.png"
import emailicon from "./Images/email-icon.png"
import event1 from "./Images/whyRamadan.jpeg"
import facebookicon from "./Images/facebook-icon.png";
import istagramIcon from "./Images/instagram-icon.png";
import zellebarcode from "./Images/zellebarcode.jpeg";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// import room1 from "./Images/room1.jpeg"
// import room2 from "./Images/room2.jpeg"
import room3 from "./Images/room3.jpeg"
import room4 from "./Images/room4.jpeg"
import room5 from "./Images/room5.jpeg"
// import room6 from "./Images/room6.jpeg"
// import room7 from "./Images/room7.jpeg"
import room8 from "./Images/room8.jpeg"
import coverpic1 from "./Images/coverpic1.jpeg"
import coverpic2 from "./Images/coverpic2.jpeg"
import coverpic3 from "./Images/coverpic3.jpeg"
import coverpic4 from "./Images/coverpic4.jpeg"
import coverpic5 from "./Images/coverpic5.jpeg"
import coverpic6 from "./Images/coverpic6.jpeg"
import coverpic7 from "./Images/coverpic7.jpeg"
import coverpic8 from "./Images/coverpic8.jpeg"
import coverpic9 from "./Images/coverpic9.jpeg"

import firstTimeTaraweeh from "./Images/firstTimeTaraweeh.jpeg"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


import AccessTime from '@mui/icons-material/AccessTime';
import Favorite from '@mui/icons-material/Favorite'
import ModeNight from '@mui/icons-material/ModeNight'
import CalendarToday from '@mui/icons-material/CalendarToday'
import AppRegistration from '@mui/icons-material/AppRegistration'
import LibraryBooks from '@mui/icons-material/LibraryBooks'
import RamadhanExcuseLetter from "./RamadhanExcuseLetter.pdf"
import NikahForm from "./Images/Nikah-form.pdf"


const useStyles = makeStyles((theme) => ({
    overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: 'red',
      }
    },
  },
  root: {
    "& .MuiPaper-root": {
     position:'sticky'
    },
    "& .MuiTabs-root":{
        background:'red'
    },
    "& .MuiBox-root": {
      paddingLeft: '0px',
      paddingRight: '0px',
    paddingTop: '10px'
      // background:'transparent'
    }, 
    "& .MuiButtonBase-root": {
      display:'flex'
    },
    "& .MuiTypography-root": {
      marginLeft: "134px",
      marginRight: "143px"
    }
  
  },  
  header: {
    backgroundImage: `url(${headerBackground})`,
    position:'sticky !important'
  },
   logo: {
     height: '100px',
     "mix-blend-mode": "lighten"
  },
  headerText:{
    paddingLeft: '20px',
    
  },
  toolbar: {
    justifyContent: 'center'
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function App(props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState(false);


   const classes = useStyles();
 const theme = useTheme();
  const [value, setValue] = React.useState(0);
   const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const form = useRef();

const sendEmail = (e) => {
e.preventDefault();
// service_id, templte_id and public key will get from Emailjs website when you create account and add template service and email service 
emailjs.sendForm('service_cqbqrap', 'template_wzhe80x', form.current, 
'3XdP9-SSmZWOA3CMd')
  .then((result) => {
     setState({open: true });
      console.log(result.text);
  }, (error) => {
      console.log(error.text);
  });
  };
  
  // component

    var items = [
        {
            name: "Random Name #1",
            image: masjidImage
      },
      //   {
      //       name: "Random Name #2",
      //       image: room1
      // },
      //    {
      //       name: "Random Name #2",
      //       image: room2
      // },
          {
            name: "Random Name #2",
            image: coverpic1
      },
            {
            name: "Random Name #2",
            image: coverpic2
      },
            {
            name: "Random Name #2",
            image: coverpic3
      },
            {
            name: "Random Name #2",
            image: coverpic4
      },
            {
            name: "Random Name #2",
            image: coverpic5
      },
            {
            name: "Random Name #2",
            image: coverpic6
      },
            {
            name: "Random Name #2",
            image: coverpic7
      },
            {
            name: "Random Name #2",
            image: coverpic8
      },
            {
            name: "Random Name #2",
            image: coverpic9
      },
      {
            name: "Random Name #2",
            image: room3
      },
           {
            name: "Random Name #2",
            image: room4
      },
            {
            name: "Random Name #2",
            image: room5
      },
      //        {
      //       name: "Random Name #2",
      //       image: room6
      // },
      //         {
      //       name: "Random Name #2",
      //       image: room7
      // },
        {
            name: "Random Name #2",
            image: room8
      },
        
  ]

  function Item(props)
{
    return (
        <Paper className="homepageImageContainer">
            {/* <h2>{props.item.name}</h2> */}
        {/* <p>{props.item.description}</p> */}
        <img id="" style={{ borderRadius: "12px", width: '100%', height: '500px', objectFit: "cover",
  objectPosition:"center" }} src={props.item.image} />
        </Paper>
    )
}
  
  const PageOne = () =>  <div className="homeContainer">  <TabPanel value={value} index={0} dir={theme.direction}>
    <Card>
      {/* &amp;autoplay=1&mute=1&controls=0&loop=1 */}
       <Typography
                  gutterBottom
                  style={{ textAlign: "center",fontSize:'x-large',paddingTop:'18px',paddingBottom:'11px' }}
                  variant="h5"
                  component="div"
                >
                  Welcome to Anna Islamic Center
      </Typography>
      {/* https://www.youtube.com/watch?v=LIBTyL9I9_o&ab_channel=AnnaIslamicCenter */}
 
      <iframe   height="315" style={{    borderRadius:'20px',width:'-webkit-fill-available'}}
src="https://www.youtube.com/embed/LIBTyL9I9_o?si=9XQXQN9hjJBCljLj?rel=0">
</iframe>
         {/* <CardContent>
                <Typography
                  gutterBottom
                  style={{ textAlign: "center",fontSize:'x-large' }}
                  variant="h5"
                  component="div"
                >
                  Welcome to Anna Islamic Center
                </Typography>
              </CardContent> */}
            {/* <CardActionArea id="twoColumns" className={classes.twoColumns}> */}
              {/* <CardMedia
                component="img"
                style={{ borderRadius: "12px" }}
                height="450"
                image={masjidImage}
              /> */}
                 {/* <Carousel>
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel> */}
          <CardContent>
                {/* <Typography
                  gutterBottom
                  style={{ textAlign: "center",fontSize:'x-large' }}
                  variant="h5"
                  component="div"
                >
                  Welcome to Anna Islamic Center
                </Typography> */}
                <Typography
                  variant="subtitle1"
                  style={{ textAlign: "center",fontSize:"medium" }}
                  color="text.secondary"
                >
            Anna Islamic Center (AIC) is home to Muslims residing in Anna, Melissa, Van Alstyne and surrounding areas. The vision of AIC is to provide religious and social services to all Muslims. All of our activities are rooted in the Quran and seerah of the Prophet Muhammad (peace be upon him). <br />
            AIC strives to foster a deeper understanding of Islam promoting practice that encompasses prayer, knowledge, and service to all of humanity. With a focus on community, AIC strives to meet the socio-religious needs of the ever growing community by facilitating an array of educational and community enrichment programs. The masjid currently offers five daily prayers service and weekly Jummah (Friday) service.
                </Typography>
      </CardContent> 
      </Card>
            {/* </CardActionArea>  
          </Card>{" "}
          <Divider />
          <br />
          {/* <div 
            id="PrayerTimings"
            gutterBottom
            style={{ textAlign: "center",fontSize:'large' }}
            variant="h5"
            // component="div"
          >
            Prayer Timings
          </div> */}
    <Card variant="outlined">
      <div style={{display:'flex'}}> 
        {/* {<a data-masjidbox-widget="x9txPC7n9Wc1aJI8cDFYI" data-masjidbox-ifr href="https://masjidbox.com/prayer-times/anna-islamic-center">Prayer times Anna Islamic Center</a>} */}
        {/* <iframe className="prayerRoute" style={{ width: 'auto' }} src="https://portal.connectmazjid.com/widget/prayer-timing?lat=33.34482051546714&lon=-96.55578279895515&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhc3NldFR5cGUiOiJ0aW1ldGFibGUiLCJtYXNqaWRJZCI6IjY2MGQ3N2MzYmZiOGEyNDM2ZTA4YzM3YSIsInVzZXJJZCI6IjY1NWI2ZjIyNTVjMWQxODUyMWMwMDRiOCIsImlhdCI6MTcxOTk0MjU0Nn0.0ukqlUV_DcMeBeMhghymGohcn7lrtLZ30ZI2k96tkw4" type="text/html" height="560" frameborder="0" allowtransparency="true"></iframe> */}
         <iframe title="prayer-timing  Widget Of Anna Islamic Center"  src="https://widgets.connectmazjid.com/widget/prayer-timing?lat=33.34482051546714&lon=-96.55578279895515&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhc3NldFR5cGUiOiJ0aW1ldGFibGUiLCJtYXNqaWRJZCI6IjY2MGQ3N2MzYmZiOGEyNDM2ZTA4YzM3YSIsInVzZXJJZCI6IjY1NWI2ZjIyNTVjMWQxODUyMWMwMDRiOCIsImlhdCI6MTcyMTA1MDExMH0.m0ZR5kNH34hYtF-5l89b1ZqXi4FRmHWxcPbKSo_j_H8"  width="380" height="640"scrolling="no"></iframe>

        <div id="calenderDesktopOnly" className="panel panel-danger" style={{ width: '100%', height: '100%' }}>
                <div className="panel-heading">
                    <h3 className="panel-title">
                        <span className="glyphicon glyphicon-calendar"></span> 
                        Calendar Events
                    </h3>
                </div>
          <div className="panel-body">
              <div className="elfsight-app-20a6b227-cf1a-4a2d-9081-dc145560d20e" data-elfsight-app-lazy/>
                      {/* <ul className="media-list">
                          <li className="media">
                              <div className="media-left">
                                  <div className="panel panel-danger text-center date">
                                      <div className="panel-heading month">
                                          <span className="panel-title strong">
                                              Mar
                                          </span>
                                      </div>
                                      <div className="panel-body day text-danger">
                                          8
                                      </div>
                                  </div>
                              </div>
                              <div className="media-body">
                                  <h4 style={{textAlign:'left'}}><a href="/events" style={{width:'100%',fontSize:'large',color:'black',textDecoration:'underline'}}>Why Ramadan "Family lecture"</a></h4>
                              </div>
                          </li>
                          <li className="media">
                              <div className="media-left">
                                  <div className="panel panel-danger text-center date">
                                      <div className="panel-heading month">
                                          <span className="panel-title strong">
                                              Mar
                                          </span>
                                      </div>
                                      <div className="panel-body text-danger day">
                                          10
                                      </div>
                                  </div>
                              </div>
                              <div className="media-body">
                                  <h4 style={{textAlign:'left'}}>
                                      Taraweeh Prayer
                                  </h4>
                              </div>
                          </li>
                          <li className="media">
                  
                          </li>
                      </ul> */}
                    {/* <a href="#" className="btn btn-default btn-block">More Events »</a> */}
                </div>
            </div>
        
    </div>
    </Card>
    <br />
  <Divider />
          <br />
          {/* <Typography
            gutterBottom
            style={{ textAlign: "center" }}
            variant="h5"
            component="div"
          >
            Upcoming Events
    </Typography> 
    <Card variant="outlined">
      <img className="upcomingeventimg" src={event1} />
      <Button
           className="eventRegister"
              style={{
                marginLeft: "13px",fontWeight:'bold', padding: "17px", color: '#fff',background:'#6da170', marginTop:'9px',marginBottom: '14px' }}
          variant="contained"
          onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSe3KKnl0GrObbyIhRbO6NJ03zcTBTsCVVPNBL2ObweBaO3JKA/viewform','_blank')}
        >
          Register
        </Button></Card>  */}
    <div className="container-fluid" style={{padding:'0px'}}>
	<div className="row">
		<div className="col-md-12">    
    	    <div className="panel panel-danger"  id="calenderMobileOnly">
                <div className="panel-heading">
                    <h3 className="panel-title">
                        <span className="glyphicon glyphicon-calendar"></span> 
                        Calendar Events
                    </h3>
                </div>
            <div className="panel-body">
                            <div className="elfsight-app-20a6b227-cf1a-4a2d-9081-dc145560d20e" data-elfsight-app-lazy/>
                    {/* <ul className="media-list">
                        <li className="media">
                            <div className="media-left">
                                <div className="panel panel-danger text-center date">
                                    <div className="panel-heading month">
                                        <span className="panel-title strong">
                                            Feb
                                        </span>
                                    </div>
                                    <div className="panel-body day text-danger">
                                        25
                                    </div>
                                </div>
                            </div>
                            <div className="media-body">
                                <h4 style={{textAlign:'left'}}><a href="/events" style={{width:'100%',fontSize:'large',color:'black',textDecoration:'underline'}}>Why Ramadan "Family lecture"</a></h4>
                           
                            </div>
                        </li>
                        <li className="media">
                            <div className="media-left">
                                <div className="panel panel-danger text-center date">
                                    <div className="panel-heading month">
                                        <span className="panel-title strong">
                                            Mar
                                        </span>
                                    </div>
                                    <div className="panel-body text-danger day">
                                        10
                                    </div>
                                </div>
                            </div>
                            <div className="media-body">
                                <h4 style={{textAlign:'left'}}>
                                    Taraweeh Prayer
                                </h4>
                          
                            </div>
                        </li>
                        <li className="media">
                           
                   
                        </li>
                    </ul> */}
                    {/* <a href="#" className="btn btn-default btn-block">More Events »</a> */}
                </div>
            </div>
		</div>
	</div>
</div>
  </TabPanel>
  </div>;

  // RAMADAN 2024/1445
  
  const handleIftak = () => window.location.href = "https://forms.gle/xpr677pjGkNb2Bzb8";

  const handleExcueForm = () => window.location.href = RamadhanExcuseLetter;

  const handleItikaf = () => window.location.href = "https://forms.gle/PjWZsV2yPrYoVPu47";

  // const RamadanPage = () => (
  //   <Card className="aboutusContainer" variant="outlined">
  //      <CardContent> 
  //               <Typography
  //                 gutterBottom
  //                 style={{ textAlign: "center",fontSize:'x-large' }}
  //                 variant="h5"
  //                 component="div"
  //               >
  //                RAMADAN 2024/1445
  //       </Typography>
  //         <CardMedia
  //               component="img"
  //               style={{ borderRadius: "12px" }}
  //               height="450"
  //              image={firstTimeTaraweeh}
  //               id="firstTimeImg"  
  //             />
  //          <Typography
  //                 variant="subtitle1"
  //                 style={{ textAlign: "center",fontSize:"medium" }}
  //       color="text.secondary">
  //       Ramadan Kareem! Here, you will find all Ramadan-related resources from the Anna Islamic Center (AIC), including iftar timings, upcoming events and announcements from the administration. If you have any questions, please email contact@annaislamiccenter.com.
  //       </Typography>
  //       <div className="ramdanContainer">
  //          {/* <div className="ramadanDivButton"> 
  //           <Button className="ramdanButtons" variant="contained" endIcon={<AccessTime />}> Prayer Times Calendar</Button>
  //         </div> */}
  //          <div className="ramadanDivButton"> 
  //           <Button className="ramdanButtons" variant="contained" onClick={() => window.location.href = "https://forms.gle/wF8pVTmjp98UxE166"} endIcon={<Favorite />}> Community Iftar - Mar-15</Button>
  //         </div> 
  //           <div className="ramadanDivButton"> 
  //           <Button className="ramdanButtons" variant="contained" onClick={() => window.location.href = "https://forms.gle/y3dHBL8kZpAm2Soq5"} endIcon={<Favorite />}> Community Iftar - Mar-22</Button>
  //         </div> 
  //         <div className="ramadanDivButton"> 
  //           <Button className="ramdanButtons" variant="contained" onClick={handleIftak} endIcon={<Favorite />}> Sponsor an Iftar</Button>
  //         </div> 
  //            {/* <div className="ramadanDivButton"> 
  //           <Button className="ramdanButtons" variant="contained" endIcon={<ModeNight />}> Taraweeh Schedule</Button>
  //         </div>   */}
  //         {/* <div className="ramadanDivButton"> 
  //           <Button className="ramdanButtons" variant="contained" endIcon={<CalendarToday />}> Ramadan Events</Button>
  //         </div>  */}
  //        <div className="ramadanDivButton"> 
  //           <Button className="ramdanButtons" variant="contained" onClick={handleItikaf} endIcon={<AppRegistration />}>I'tikaf Registration</Button>
  //         </div> 
  //       <div className="ramadanDivButton"> 
  //           <Button className="ramdanButtons" variant="contained" onClick={handleExcueForm} endIcon={<LibraryBooks />}>Ramadan excuse form</Button>
  //           </div>   
  //        </div>
  //       </CardContent>
      
  //     </Card>
  // )

  // event1
  
  // education
  
  const Education = () => (
    <Card className="aboutusContainer" variant="outlined"> 
      <Stack direction="row" spacing={1} style={{ overflow: 'auto', display: 'flex', justifyContent: 'center', marginTop: '41px' }}>
        <Chip onClick={() => navigate("/education")} label="Maktab" color={"primary"}  style={{fontSize:'17px',cursor:'pointer'}}></Chip>
        <Chip onClick={() => navigate("/sundayschool")} label="Sunday School" color={"primary"} variant="outlined" style={{fontSize:'17px',cursor:'pointer'}} />
        <Chip onClick={() => navigate("/paymentportal")} label="Payment Portal"  color="primary" variant="outlined" style={{ fontSize: '17px', cursor: 'pointer' }} />
        <Chip  onClick={() => navigate("/rareLeaning")} label="RARE Learning" color="primary" variant="outlined" style={{ fontSize: '17px', cursor: 'pointer' }} />
         {/* <Chip onClick={() => navigate("/summerCampRegistration")} label="Summer Camp Registration"  color="primary"  variant="outlined" style={{fontSize:'17px',cursor:'pointer'}} /> */}
    </Stack>
      <TabPanel>
         {/* <img id="summercampimg" src={summercamp} /> */}
        {/* <ButtonGroup style={{display:'flex',justifyContent:'center', marginBottom:'17px',marginTop:'15px'}} variant="outlined" aria-label="Loading button group">
          <div>
          <Button style={{fontSize:'20px',fontWeight:600}}  onClick={() => navigate("/summerCampRegistration")}>Summer Camp Registration</Button>
          </div>
        </ButtonGroup> */}<br/><br/>
        <div>
        </div>
        {/* <hr style={{ borderTop: '1px solid #8c8b8b' }} /> */}
          <Typography variant="h4" style={{ textAlign: "left" }}>
            After School MAKTAB
        </Typography><br/>
          <br />
          <Typography
            variant="subtitle1"
            style={{ textAlign: "left",fontSize:"15px" }}
            color="text.secondary"
        >
          <div>Give your child the gift of knowledge and imaan. Join our After School Maktab today and embark on a journey of spiritual growth and academic excellence! We aim not only on memorization but also on understanding and applying values and teachings of the quran. Our interactive sessions make learning of the Quran an enjoyable journey. Our small teacher to student ratio ensures that your child receives lessons that are tailored to cater their individual learning pace and needs. The Maktab is scheduled after school to easily fit your lifestyle and provide consistency.</div>
          <br/>
          <div>Spaces are limited, so don't miss out on this wonderful opportunity!</div><br/>
          <div><b>CLASSES BEGIN: August 15, 2024</b></div>
          <div><b>Program Timings: Mon-Thurs: 6:30 PM – 8:00 PM</b></div>
          <div><b>Cost: Tuition $150/month (20% Sibiling Discount)</b></div><br/>
          <div><b>Please complete the form below to enroll your child (ages 6+) in Anna Islamic Center's Maktab program.</b></div><br/>
              {/* <Typography variant="h4" style={{ textAlign: "left",color:'red' }}>
            Registration is now closed! */}
          {/* </Typography> */}
           {/* <Typography variant="h4" style={{ textAlign: "left",fontWeight:'bold' }}>We appreciate your patience at this time as we work to accommodate students based on facility and staffing availability.</Typography><br /> */}
        <div style={{ display: 'flex', justifyContent: 'center' }}> 
            </div>
          <iframe src="https://forms.gle/tcP5oZF2UuZ8jHhH9"
            width="540"
            height="305"
            frameborder="0"
            allowfullscreen
            style={{ display: "block", height: "1000px", width: "100%" }}
          />
          </Typography>
      </TabPanel>
      </Card>
  )

  const PayEducationFee = () => (
    <>
     <Card className="aboutusContainer" variant="outlined"> 
        <Stack direction="row" spacing={1} style={{ overflow: 'auto', display: 'flex', justifyContent: 'center', marginTop: '41px' }}>
          <Chip onClick={() => navigate("/education")} variant="outlined" label="Maktab" color={"primary"}  style={{fontSize:'17px',cursor:'pointer'}}></Chip>
        <Chip onClick={() => navigate("/sundayschool")} label="Sunday School" color={"primary"}  variant="outlined" style={{fontSize:'17px',cursor:'pointer'}} />
        <Chip onClick={() => navigate("/paymentportal")} label="Payment Portal"  color="primary"  style={{ fontSize: '17px', cursor: 'pointer' }} />
        <Chip  onClick={() => navigate("/rareLeaning")} label="RARE Learning" color="primary" variant="outlined" style={{ fontSize: '17px', cursor: 'pointer' }} />
         {/* <Chip onClick={() => navigate("/summerCampRegistration")} label="Summer Camp Registration"  color="primary"  variant="outlined" style={{fontSize:'17px',cursor:'pointer'}} /> */}
        </Stack>
        <br/><br/><br/>
        <iframe src="https://donorbox.org/embed/sunday-school-fee-1?language=en" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style={{ maxWidth: "500px", minWidth: "250px", maxHeight: "none" }} />
 </Card>
    </>
  )

  const NikahServices = () => (
        <>
     <Card className="aboutusContainer" variant="outlined"> 
      <Stack direction="row" spacing={1}  style={{overflow:'auto',display:'grid',justifyContent:'center',marginTop:'41px',paddingLeft:'8px',paddingRight:'8px'}}>
         <div style={{fontSize:"18px",fontWeight:400, display: "flex",alignItems: "center"}}>Please fill out the Nikah application form and send it to contact@annaislamiccenter.com</div><br/>
          <Chip target="_blank" onClick={() => window.location.href = NikahForm} label="Download Nikah Application Form" color={"primary"} variant="outlined" style={{ fontSize: '17px', cursor: 'pointer' }} />
 
        </Stack>
        <br/><br/><br/>
  </Card>
    </>
  )

  //about us

  const PageTwo = () => (
     <Card className="aboutusContainer" variant="outlined"> 
    <TabPanel>
          <Typography variant="h4" style={{ textAlign: "left" }}>
            About us
          </Typography>
          <br />
          <Typography
            variant="subtitle1"
            style={{ textAlign: "center",fontSize:"15px" }}
            color="text.secondary"
          >
            Anna Islamic Center (AIC) is committed to the Islamic way
            of life based on the Quran and life examples of the prophet. We
            hope to serve as a center of religious development, social and
            educational enrichment. We are committed to promoting excellence in
            the community life and to provide charitable and humanitarian help
            to the entire community. We strive to enlighten the non-Muslims with
            the true knowledge about Islam, it's teachings, ideology and
            philosophy through our activities, which are consistent with the
            teachings and the principles of Islam. We contribute to strengthen
            the bonds between Muslims and Non-Muslims alike by establishing a
            united community through effective interfaith partnerships,
            tolerance, and respect to all the people.
          </Typography>
          <br />
          <Typography
            variant="subtitle1"
            style={{ textAlign: "left",fontSize:"15px" }}
            color="text.secondary"
          >
            <b>Vision:</b> Our vision is to establish an Islamic community
            environment based on the model of the community of the prophet
            Mohammed (PBUH) to exemplify the best of character by contributing
            to the neighborhoods in which we live, through intense attention to
            the spiritual, educational and material needs of our unified
            community, in a transparent, inclusive and equitable manner.
          </Typography>
          <br />
          <Typography
            variant="subtitle1"
            style={{ textAlign: "left",fontSize:"15px" }}
            color="text.secondary"
          >
            <b>Mission:</b> To serve the Muslim community by answering their
            religious needs and providing them with a place of worship, classes
            to assist them and their children where they learn detailed
            knowledge about their religion Islam.
          </Typography>
      </TabPanel>
      </Card>
  )
  
  // SERVICES

  const PageThree = () =>   <Card className="aboutusContainer" variant="outlined"> 
          <Typography variant="h4" style={{ textAlign: "center",paddingTop:'21px' }}>
            Services
          </Typography>
          <br />
          <Card variant="outlined">
            <div
              id="servicesImages"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                justifyContent: "center",
                alignItems: "center",
              }}
      >
           <ButtonGroup style={{display:'grid',justifyContent:'center', marginBottom:'17px',marginTop:'15px'}} variant="outlined" aria-label="Loading button group">
          <div>
          <Button style={{fontSize:'20px',fontWeight:600}}  onClick={() => navigate("/nikahServices")}>Nikah Services</Button>
          </div>
          <br/>
          <div>
          <Button style={{fontSize:'20px',fontWeight:600}} target="_blank"  href="https://docs.google.com/forms/d/1mC0New8PFnMQ8XzkluzBqRHZ-5_Vhh4J9IOyXW63SEY">Financial AID</Button>
          </div>
        </ButtonGroup>
         
              {/* <div style={{ paddingRight: "10px" }}>
                <img className="servicesImg" src={servicesDailyNamaz} />
              </div>
              <div style={{ paddingRight: "10px" }}>
                <img className="servicesImg" src={servicesNikah} />
              </div>
              <div style={{ paddingRight: "10px" }}>
                <img className="servicesImg" src={servicesCommunity} />
              </div>
              <div style={{ paddingRight: "10px" }}>
               <a target="_blank" href="https://docs.google.com/forms/d/1mC0New8PFnMQ8XzkluzBqRHZ-5_Vhh4J9IOyXW63SEY"> <img className="servicesImg" src={servicesFinacialaid} /> </a>
              </div> */}
            </div>
          </Card>
  </Card>
  
  // Donate

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

  
  const PageFour = () => {
     const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
    return (
      <Card className="aboutusContainer" variant="outlined">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Modal title
        </DialogTitle> */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <img src={zellebarcode}  style={{width:'100%'}}/>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
          <Typography variant="h4" style={{ textAlign: "center",paddingTop:'21px',paddingBottom:'10px' }}>
            Donate
          </Typography>
          <div
            id="servicesImages"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
          <div style={{ paddingRight: "10px" }}>
             <a target="_blank" href="https://enroll.zellepay.com/qr-codes?data=eyJuYW1lIjoiQU5OQSBJU0xBTUlDIENFTlRFUiIsInRva2VuIjoiZG9uYXRlQGFubmFpc2xhbWljY2VudGVyLmNvbSIsImFjdGlvbiI6InBheW1lbnQifQ=="> 
              <img
                style={{ width: "390px",cursor:'pointer' }}
                className="servicesImg"
          src={zelleImage}
          // onClick={handleClickOpen}
              />
              </a>
            </div>
          <div style={{ paddingRight: "10px" }}>
            <a href="/makeapledge">
              <img
                style={{ width: "390px" }}
                className="servicesImg"
                src={pledgeImage}
            />
           </a> 
          </div>
             <div style={{ paddingRight: "10px" }}>
              <img
                style={{ width: "390px" }}
                className="servicesImg"
                src={checkImage}
              />
            </div> 
            <div id="donorBox">
              <iframe
                src="https://donorbox.org/embed/icam-donation"
                name="donorbox"
                allowpaymentrequest="allowpaymentrequest"
                seamless="seamless"
                frameborder="0"
                scrolling="no"
                height="900px"
                width="100%"
                style={{ minWidth: "250px", maxHeight: "none" }}
              ></iframe>
          </div>
        </div>        
  </Card>
     )
  };

  // SummerCampRegistration - https://forms.gle/UZWLduVWP1YX6AVe9
//   const SummerCampRegistration = () => (
//     <Card className="aboutusContainer" variant="outlined"> 
//      <Stack direction="row" spacing={1}  style={{overflow:'auto',display:'flex',justifyContent:'center',marginTop:'41px'}}>
//         <Chip onClick={() => navigate("/education")} label="Sunday School Registration" color={"primary"}  variant="outlined" style={{fontSize:'17px',cursor:'pointer'}} />
//         <Chip onClick={() => navigate("/paymentportal")} label="Sunday School Payment Portal" variant="outlined"  color="primary"  style={{ fontSize: '17px', cursor: 'pointer' }} />
//         <Chip  onClick={() => navigate("/rareLeaning")} label="RARE Learning" color="primary" variant="outlined" style={{ fontSize: '17px', cursor: 'pointer' }} />
//          <Chip onClick={() => navigate("/summerCampRegistration")} label="Summer Camp Registration"  color="primary"  style={{fontSize:'17px',cursor:'pointer'}} />
//         </Stack>
//         <br/><br/><br/>
// =    <iframe
//       width="540"
//       height="305"
//       frameborder="0"
//       allowfullscreen
//       style={{ display: "block", height: "1000px", width: "100%",background:'#fffff' }}
//       src="https://forms.gle/UZWLduVWP1YX6AVe9">

//     </iframe>
//     </Card>)
  
  const RareLeaning = () =>  (
    <Card className="aboutusContainer" variant="outlined"> 
      <Stack direction="row" spacing={1} style={{ overflow: 'auto', display: 'flex', justifyContent: 'center', marginTop: '41px' }}>
        <Chip onClick={() => navigate("/education")} label="Maktab" color={"primary"}  variant="outlined" style={{fontSize:'17px',cursor:'pointer'}}></Chip>
        <Chip onClick={() => navigate("/sundayschool")} label="Sunday School" color={"primary"}  variant="outlined" style={{fontSize:'17px',cursor:'pointer'}} />
        <Chip onClick={() => navigate("/paymentportal")} label="Payment Portal" variant="outlined"  color="primary"  style={{ fontSize: '17px', cursor: 'pointer' }} />
        <Chip onClick={() => navigate("/rareLeaning")} label="RARE Learning" color="primary"  style={{ fontSize: '17px', cursor: 'pointer' }} />
         {/* <Chip onClick={() => navigate("/summerCampRegistration")} variant="outlined" label="Summer Camp Registration"  color="primary"  style={{fontSize:'17px',cursor:'pointer'}} /> */}
        </Stack>
      <br /><br /><br />
       <ButtonGroup style={{display:'flex',justifyContent:'center', marginBottom:'14px'}} variant="outlined" aria-label="Loading button group">
          <div>
          <Button style={{fontSize:'16px'}} target="_blank"  href="https://forms.gle/B7M3jbn6svJ6Zqr17">RARE Leaning at AIC Hiring now</Button>
          </div>
        </ButtonGroup>
   <iframe
      width="540"
      height="305"
      frameborder="0"
      allowfullscreen
      style={{ display: "block", height: "1000px", width: "100%",background:'#fffff' }}
      src="https://forms.gle/HRCCeEtazbeFXQHb8">

    </iframe>
    </Card>
  )

  const Maktab = () => (
       <Card className="aboutusContainer" variant="outlined"> 
     <Stack direction="row" spacing={1}  style={{overflow:'auto',display:'flex',justifyContent:'center',marginTop:'41px'}}>
        <Chip onClick={() => navigate("/education")} label="Maktab" color={"primary"}  variant="outlined"  style={{fontSize:'17px',cursor:'pointer'}} />
        <Chip onClick={() => navigate("/sundayschool")} label="Sunday School" color={"primary"} style={{ fontSize: '17px', cursor: 'pointer' }} />
        <Chip onClick={() => navigate("/paymentportal")} label="Payment Portal" variant="outlined"  color="primary"  style={{ fontSize: '17px', cursor: 'pointer' }} />
        <Chip onClick={() => navigate("/rareLeaning")} label="RARE Learning"  variant="outlined" color="primary"  style={{ fontSize: '17px', cursor: 'pointer' }} />
         {/* <Chip onClick={() => navigate("/summerCampRegistration")} variant="outlined" label="Summer Camp Registration"  color="primary"  style={{fontSize:'17px',cursor:'pointer'}} /> */}
        </Stack>
     <TabPanel>
         {/* <img id="summercampimg" src={summercamp} /> */}
        {/* <ButtonGroup style={{display:'flex',justifyContent:'center', marginBottom:'17px',marginTop:'15px'}} variant="outlined" aria-label="Loading button group">
          <div>
          <Button style={{fontSize:'20px',fontWeight:600}}  onClick={() => navigate("/summerCampRegistration")}>Summer Camp Registration</Button>
          </div>
        </ButtonGroup> */}<br/><br/>
        <div>
        </div>
        {/* <hr style={{ borderTop: '1px solid #8c8b8b' }} /> */}
          <Typography variant="h4" style={{ textAlign: "left" }}>
            Sunday School Registration
          </Typography>
          <br />
          <Typography
            variant="subtitle1"
            style={{ textAlign: "left",fontSize:"15px" }}
            color="text.secondary"
        >
          <div><b>Timing: Every Sunday 10:30am - 1:30pm</b></div>
          <ul>
            <li>Classes: Tajweed, Nazra, Noorani Qaida, Prayer, Duas, Kalima, Stories of Prophets & Sahaba, Hifz and Tafsir, Arabic </li>
            <li>Fee: $100 per child per month (25% sibling discount)</li>
            <li>Age Limit: 6+ years</li>
          </ul>  
          <div><b>Please complete the form below to enroll your child (ages 6+) in Anna Islamic Center's Sunday School program.</b></div><br/>
              {/* <Typography variant="h4" style={{ textAlign: "left",color:'red' }}>
            Registration is now closed! */}
          {/* </Typography> */}
           {/* <Typography variant="h4" style={{ textAlign: "left",fontWeight:'bold' }}>We appreciate your patience at this time as we work to accommodate students based on facility and staffing availability.</Typography><br /> */}
         <div style={{ display: 'flex', justifyContent: 'center' }}> 
            </div>
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScrvHzQ_Of5HfwpMt6CF6Aabq59gV5zxnLwU6mnWh-s3_0m1w/viewform"
            width="540"
            height="305"
            frameborder="0"
            allowfullscreen
            style={{ display: "block", height: "1000px", width: "100%" }}
          />
          </Typography>
      </TabPanel>
    </Card>
  )
  
  // membership

  const PageFive = () =>   ( <Card className="aboutusContainer" variant="outlined">
     <Typography variant="h4" style={{ textAlign: "center",paddingTop:'21px' }}>
            Membership
          </Typography>
          <iframe
            width="540"
            height="305"
            src="https://35288c9a.sibforms.com/serve/MUIFAP-SRwPZDTdPtI1jggyZuDEAaJB21qr9MsW9Ub-j2KqBztS9arEIwxfJwSiqdtdvtDg8VwyqAF6NmFqlmWK1aHza59fBCbTjbjUlkm1-VwAtdwLAqUJnd9xMwehlNzq0XuZeckHRnzDzhPOBeIWeDlfS-e99xZCJuuM7JEAk_cij5GJZ7sw-M3LEnIGiZnxPzpDJInS--h8X"
            frameborder="0"
            allowfullscreen
            style={{ display: "block", height: "1000px", width: "100%" }}
          ></iframe>
  </Card>)
  
  // contact us
 
  const Pagesix = () => (
    <Card className="aboutusContainer" variant="outlined">
          {/* <Snackbar
        anchorOrigin={{
  vertical: 'bottom',
  horizontal: 'top',
}}
        open={open}
        message="I love snacks"
        key={vertical + horizontal}
      /> */}
          <div className="container">
            <div className="content">
              <div className="left-side">
                <div className="address details">
                  <i className="fas fa-map-marker-alt"></i>
                  <div className="topic">Address</div>
                  <div className="text-one">604 W White St, Suite A, Anna, TX</div>
                  {/* <div className="text-two">Birendranagar 06</div> */}
                </div>
                {/* <div className="phone details">
          <i className="fas fa-phone-alt"></i>
          <div className="topic">Phone</div>
          <div className="text-one">+0098 9893 5647</Pdiv>
          <div className="text-two">+0096 3434 5678</div>
        </div> */}
                <div className="email details">
                  <i className="fas fa-envelope"></i>
                  <div className="topic">Email</div>
                  <div className="text-one">admin@annaislamiccenter.com</div>
                  {/* <div className="text-two">info.codinglab@gmail.com</div> */}
                </div>
                <div className="email details">
                  <i className="fa fa-whatsapp" aria-hidden="true"></i>
                  <div className="topic">
                    <a href="https://chat.whatsapp.com/GIuwRN3t25UHY1HcSEfaFY">
                      Join us on whatsapp
                    </a>
                  </div>
                  {/* <div className="text-two">info.codinglab@gmail.com</div> */}
                </div>
              </div>
              <div className="right-side">
                <div className="topic-text">Send us a message</div>
                <p>
                  Please fill the form below to contact us or express interest
                  in a special event or service opportunity.
                </p>
                <form ref={form} onSubmit={sendEmail}>
                  <div className="input-box">
                    <input type="text" placeholder="Name" name="user_name" />
                  </div>
                  <div className="input-box">
                    <input type="text" placeholder="Email" name="user_email" />
                  </div>
                  <div className="input-box">
                    <textarea
                      placeholder="Question, comments or message"
                      name="comments"
                    />
                  </div>
                  <div className="button">
                    <input type="submit" value="Send Now" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Card>
  )

  const PageEight = () => (
    <Card className="aboutusContainer" variant="outlined">
          <Typography variant="h4" style={{ textAlign: "center",padding:'15px',fontWeight:'400' }}>
            Upcoming Events
          </Typography>
      <img style={{width:'100%'}} src={event1}/>
     </Card>
  )

  const PageNine = () => (
    <Card className="aboutusContainer" variant="outlined">
            {/* <div 
            id="PrayerTimings"
            gutterBottom
            style={{ textAlign: "center",fontSize:'large',padding:'5px',fontWeight:'500' }}
            variant="h5"
            // component="div"
          >
            Prayer Timings
          </div> */}
    <Card variant="outlined">
      <div style={{display:'flex'}}> 
        {/* {<a data-masjidbox-widget="x9txPC7n9Wc1aJI8cDFYI" data-masjidbox-ifr href="https://masjidbox.com/prayer-times/anna-islamic-center">Prayer times Anna Islamic Center</a>} */}
        {/* <iframe className="prayerRoute" style={{ width: 'auto' }} src="https://portal.connectmazjid.com/widget/prayer-timing?lat=33.34482051546714&lon=-96.55578279895515&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhc3NldFR5cGUiOiJ0aW1ldGFibGUiLCJtYXNqaWRJZCI6IjY2MGQ3N2MzYmZiOGEyNDM2ZTA4YzM3YSIsInVzZXJJZCI6IjY1NWI2ZjIyNTVjMWQxODUyMWMwMDRiOCIsImlhdCI6MTcxOTk0MjU0Nn0.0ukqlUV_DcMeBeMhghymGohcn7lrtLZ30ZI2k96tkw4" type="text/html" height="560" frameborder="0" allowtransparency="true"></iframe> */}
         {/* <iframe title="prayer-timing  Widget Of Anna Islamic Center"  src="https://widgets.connectmazjid.com/widget/prayer-timing?lat=33.34482051546714&lon=-96.55578279895515&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhc3NldFR5cGUiOiJ0aW1ldGFibGUiLCJtYXNqaWRJZCI6IjY2MGQ3N2MzYmZiOGEyNDM2ZTA4YzM3YSIsInVzZXJJZCI6IjY1NWI2ZjIyNTVjMWQxODUyMWMwMDRiOCIsImlhdCI6MTcyMTA1MDExMH0.m0ZR5kNH34hYtF-5l89b1ZqXi4FRmHWxcPbKSo_j_H8"  width="380" height="640"scrolling="no"></iframe> */}

          <div id="calenderDesktopOnly" className="panel panel-danger" style={{ width: '100%', height: '100%' }}>
                <div className="panel-heading">
                    <h3 className="panel-title">
                        <span className="glyphicon glyphicon-calendar"></span> 
                        Calendar Events
                    </h3>
                </div>
                <div className="panel-body">
                                <div className="elfsight-app-20a6b227-cf1a-4a2d-9081-dc145560d20e" data-elfsight-app-lazy/>

                    {/* <a href="#" className="btn btn-default btn-block">More Events »</a> */}
                </div>
            </div>
        
    </div>
    </Card>
      
</Card>
  )
  
  const PageTen = () => (
      <Card className="aboutusContainer" variant="outlined"> 
        <CardMedia
          component="img"
          height="140"
          image="/static/images/cards/contemplative-reptile.jpg"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
             
          </Typography>
          <Typography variant="body2" color="text.secondary">
           
          </Typography>
        </CardContent>
    </Card>
  )

  const Makeapledge = () => (
     <div id="donorBoxMakeapledge">
              <iframe
                src="https://donorbox.org/embed/icam-donation"
                name="donorbox"
                allowpaymentrequest="allowpaymentrequest"
                seamless="seamless"
                frameborder="0"
                scrolling="no"
                height="900px"
                width="100%"
                style={{ minWidth: "250px", maxHeight: "none" }}
              ></iframe>
            </div>
  )

  const Footer = () => (
    <footer className="footer">
      <Container>
        {/* 1 */}
        <div id="containerfooter" style={{display:'grid',gridTemplateColumns:'1fr 1fr 1fr',paddingTop:'29px',paddingBottom:'25px'}}>  
        <div> 
          <span style={{fontWeight:'bold',fontSize:'15px'}}>ABOUT US</span> 
          <div style={{marginTop:'10px', textAlign:'left',fontSize:'15px'}}>
            Anna Islamic Center (AIC) is home to Muslims residing in Anna, Melissa, Van Alstyne and surrounding areas. The vision of AIC is to provide religious and social services to all Muslims. All of our activities are rooted in the Quran and seerah of the Prophet Muhammad (peace be upon him)
           </div>
        </div>
         <div style={{marginTop:'10px'}}> 
         <span style={{ fontWeight: 'bold',fontSize:'15px' }}>CONTACT US </span>
           <div id="contactContainer" style={{ display:'grid',justifyContent:'center',fontSize:'15px',marginTop:'10px'}}>  
          <div style={{textAlign:'left'}}><img  width="40px" style={{verticalAlign:'middle'}} src={addressLogo} /> <a style={{color:'#fff'}} target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/byWMTMwSn8btCHp49">604 W White St Suite A</a> </div>
          <div style={{verticalAlign:'middle',textAlign:'left'}}><img  style={{verticalAlign:'middle'}}  width="40px" src={phoneicon} /> Phone: (214) 317 5809 </div>
          <div style={{verticalAlign:'middle'}}><img  style={{verticalAlign:'middle'}} width="40px" src={emailicon} /> Email: contact@annaislamiccenter.com</div>
          </div> 
        </div>
         <div> 
          <span style={{fontWeight:'bold',fontSize:'15px'}}>FOLLOW US</span>
           <div style={{marginTop:'10px'}}><a target="_blank"  href="https://www.facebook.com/people/Anna-Islamic-Center/61556155298903/?mibextid=LQQJ4d"><img width="40px" src={facebookicon} /></a> <a target="_blank" href="https://www.instagram.com/annaislamiccenter/"><img width="40px" src={istagramIcon} /></a></div>
          </div>
      </div>
      </Container>
      </footer>
);

  return (
     <>
  <CssBaseline />
  <ElevationScroll {...props}>
    <AppBar className={classes.header}>
      <Toolbar className={classes.toolbar}>
        <img
          src={aicLogo}
          alt="logo"
          className={classes.logo}
        />
        <Typography
          id="headerText"
          className={classes.headerText}
          variant="h3"
          component="div"
        >
          Anna Islamic Center
            </Typography>
            <div> 
        <Button
              style={{
                marginLeft: "13px", padding: "17px", color: '#fff',fontSize:'medium' }}
          variant="contained"
          onClick={() => navigate("/donate")}
        >
          Donate
        </Button></div>
      </Toolbar>
    </AppBar>
      </ElevationScroll>
       <Container> 
       <Box sx={{ my: 1, bgcolor: "background.paper" }}>
       <AppBar position="static">
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              sx={{ background: "#6da170" }}
              textColor={"inherit"} aria-label="Tab navigation"
              value={pathname}>
           <Tab
            label="Home"
            component={Link}
            to="/home"
            value="/home"
            indicatorColor="secondary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="true"
            disableHideScrollButtonsMobile="false"
            centered
              />
           {/* <Tab
            label="Ramadan"
            component={Link}
            to="/ramadan"
            value="/ramadan"
              />  */}
          <Tab
            label="Education"
            component={Link}
            to="/education"
            value="/education"
          />     
          <Tab
            label="About us"
            component={Link}
            to="/aboutus"
            value="/aboutus"
          />
          <Tab
            label="Services"
            component={Link}
            to="/services"
            value="/services"
          />
          <Tab
            label="Donate"
            component={Link}
            to="/donate"
            value="/donate"
              />
            <Tab
            label="Membership"
            component={Link}
            to="/membership"
            value="/membership"
              />
                {/* <Tab
            label="Board of Directors"
            component={Link}
            to="/boardofdirectors"
            value="/boardofdirectors"
              />   */}
              <Tab
            label="Contact us"
            component={Link}
            to="/contactus"
            value="/contactus"
              />      
          </Tabs>
          </AppBar>
                <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      ></SwipeableViews>
        </Box>
        </Container>
      {/* </TabContext> */} 
      <Routes>
        <Route index element={<Navigate to="/home" />} />
        <Route path="/home" element={<PageOne />} />
        {/* <Route path="/ramadan" element={<RamadanPage />} /> */}
        <Route path="/education" element={<Education />} />
        <Route path="/paymentportal" element={<PayEducationFee />} />
         <Route path="/nikahServices" element={<NikahServices />} />
         <Route path="/rareLeaning" element={<RareLeaning />} />
        <Route path="/aboutus" element={<PageTwo />} />
        <Route path="/services" element={<PageThree />} />
        <Route path="/donate" element={<PageFour />} />
        {/* <Route path="/summerCampRegistration" element={<SummerCampRegistration />} /> */}
         <Route path="/sundayschool" element={<Maktab />} />
        <Route path="/rareLearning" element={<RareLeaning />} />
        <Route path="/membership" element={<PageFive />} />
        <Route path="/contactus" element={<Pagesix />} /> 
        <Route path="/events" element={<PageEight />} /> 
        <Route path="/prayertimings" element={<PageNine />} /> 
        <Route path="/boardofdirectors" element={<PageTen />} /> 
        <Route path="/makeapledge" element={<Makeapledge />} /> 
      </Routes>
      <Footer />
    </>
  );
}
